import React, { useState, useEffect } from 'react';
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import Container from "../components/pages/container/container"
import ContainerFluid from "../components/pages/container/container-fluid"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import RegistrationFrom from "../components/Forms/RegistrationForm"

type DataProps = {
  data: IndexQueryQuery
  location: Location
}

const RegisterPage: React.FC<PageProps<DataProps>> = ({ location }) => {

    const data = useStaticQuery(
        graphql`
          query {
            hero_background: file(relativePath: { eq: "register-bg.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            astronaut_img: file(relativePath: { eq: "gatsby-astronaut.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        `
    )

    //declare image Data
    const hero_background = getImage(data.hero_background)
    const astronaut = getImage(data.astronaut_img)

    //for bg image
    const hero_bg = convertToBgImage(hero_background)

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            </Helmet>
            <Layout>
                <Seo title="Register" />
                <section id="registration-page">
                    <BackgroundImage
                        Tag="div"
                        className="container"
                        {...hero_bg}
                        preserveStackingContext
                    >
                        <Container>
                            <StaticImage
                                src="../images/peer-club-circle.png"
                                width={300}
                                quality={95}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="A Gatsby astronaut"
                                style={{ marginBottom: `1.45rem` }}
                                className="peerclub-circle"
                            />
                        </Container>
                    </BackgroundImage>
                    <div className="form-container">
                        <Container>
                            <RegistrationFrom />
                        </Container>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default RegisterPage
