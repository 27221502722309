import React from 'react'
import { useState, useEffect, useRef, createRef } from 'react'
import { navigate } from 'gatsby';

const initialFormData = Object.freeze({
    name: '',
    job_title: '',
    email_address: '',
    contact_number: '',
    company_website: '',
    industry_sector: '',
    domain: '',
    expertise: '',
    interests: [],
})

const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => {
  return (
    <input type={type} name={name} checked={checked} onChange={onChange} />
  );
};

function RegistrationForm() {
    const [formData, setFormData] = useState(initialFormData)
    const [currentStep, setCurrentStep] = useState(1)
    const [formStatus, setFormStatus] = useState("standby")
    const [formAnimation, setFormAnimation] = useState("slide-left")
    const [interests, setInterests] = useState({})
    const [step1, setStep1] = useState("");
    const [step2, setStep2] = useState("");
    const [step3, setStep3] = useState("");

    const handleCheckboxChange = (e) => {
      setInterests({
        ...interests,
        [e.target.name]: e.target.checked
      });
      //console.log("checkedItems: ", checkedItems);
    };

    const checkboxes = [
      {
        name: "check-box-1",
        key: "checkBox1",
        label: "Check Box 1"
      },
      {
        name: "check-box-2",
        key: "checkBox2",
        label: "Check Box 2"
      }
    ];

    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        })
    }

    const nextStep = e => {
      if (step1 == "clear") {
        setCurrentStep(currentStep + 1);
      } else if (step1 == "clear") {
        setCurrentStep(currentStep + 1);
      }
    }

    const prevStep = e => {
        setCurrentStep(currentStep - 1);
    }

    const combineFormData = e => {
      setFormData({
        ...formData,
        interests: interests
      })
    }

    async function submitForm(e) {
      const response = await fetch("https://app.4pointtkd-members.com/api/application", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
      },
      redirect: "follow",
      referrer: "client",
      body: JSON.stringify(formData),
      });

      const json = await response.json();
      //console.log(json);
      if (json.status == "Success") {
          navigate("/login");
      }
    }

    useEffect(() => {
      if (formData.name != "" && formData.job_title != "") {
        setStep1("clear");
      }

    }, [formData])

    return (
        <>
            {currentStep == 1 ?
                <div className="col-lg-12" id="reg-step-1" data-animation={formAnimation}>
                    <h2 className="reg-form-header">
                        About You
                    </h2>
                    <input type="text" className="form-control" placeholder="Name" name="name" onChange={handleChange} value={formData.name} />
                    <input type="text" className="form-control" placeholder="Job Title" name="job_title" onChange={handleChange} value={formData.job_title} />
                    <button className="btn-next btn btn-primary" onClick={nextStep}>Next</button>
                </div>
                :
                null
            }
            {currentStep == 2 && step1 == "clear" ?
                <div className="col-lg-12" id="reg-step-2" data-animation={formAnimation}>
                    <h2 className="reg-form-header">
                        About Your Company
                    </h2>
                    <input type="text" className="form-control" placeholder="Company Name" name="company_name" onChange={handleChange} />
                    <h3 className="reg-form-subheader">Contact details</h3>
                    <input type="email" className="form-control" placeholder="Email Address" name="email_address" onChange={handleChange} />
                    <input type="tel" className="form-control" placeholder="Contact Number" name="contact_number" onChange={handleChange} />
                    <input type="text" className="form-control" placeholder="Company Website" name="company_website" onChange={handleChange} />
                    <select className="form-control" onChange={handleChange} name="industry_sector">
                      <option value="">Industry Sector</option>
                      <option value="Sector 1">Sector 1</option>
                      <option value="Sector 2">Sector 2</option>
                    </select>
                    <textarea rows="4" className="form-control" placeholder="Please describe your work domain and role within" name="domain" onChange={handleChange}>
                    </textarea>
                    <textarea rows="4" className="form-control" placeholder="Please describe your core area(s) of expertise" name="expertise" onChange={handleChange}>
                    </textarea>
                    <button className="btn-prev btn btn-secondary" onClick={prevStep}>Back</button>
                    <button className="btn-next btn btn-primary" onClick={nextStep}>Next</button>
                </div>
                :
                null
            }
            {currentStep == 3 ?
                <div className="col-lg-12" id="reg-step-3" data-animation={formAnimation}>
                  <h2 className="reg-form-header">
                      About Your Interests
                  </h2>
                  <h3 className="reg-form-subheader">Sectors</h3>
                  {checkboxes.map(item => (
                    <div className="reg-checkbox-container">
                      <Checkbox
                        name={item.name}
                        checked={interests[item.name]}
                        onChange={handleCheckboxChange}
                      />
                      <label className="reg-checkbox-label" htmlFor={item.name} key={item.key}>
                        {item.label}
                      </label>
                    </div>
                  ))}
                  <div>
                    <button className="btn-prev btn btn-secondary" onClick={prevStep}>Back</button>
                    <button className="btn-next btn btn-primary" onClick={submitForm} onMouseOver={combineFormData}>Next</button>
                  </div>
                </div>
                :
                null
            }

        </>
    );
}

export default RegistrationForm
